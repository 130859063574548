/**
 * index.tsx
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import { App } from 'app';
import { identity, pipe } from 'fp-ts/function';
import { fromNullable, map, match } from 'fp-ts/Option';
import { CurrencyProvider } from 'providers/CurrencyProvider';
import { UserProvider } from 'providers/UserProvider';
import type { UserData } from 'providers/UserProvider/types';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { init as amplitudeInit } from '@amplitude/analytics-browser';

import 'react-toastify/dist/ReactToastify.css';
import { configureAppStore } from 'store/configureStore';
import './locales/i18n';
import reportWebVitals from './reportWebVitals';
import './styles/fonts.css';
import Config from './utils/config';
import { GlobalAppProvider } from 'providers/AppPovider';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE!);

const store = configureAppStore();

const initUser: UserData | null = pipe(
  localStorage.getItem('user'),
  fromNullable,
  map(JSON.parse),
  match(() => null, identity),
);

// Only load HubSpot script in development environment
if (process.env.NODE_ENV === 'development') {
  const hubspotScript = document.createElement('script');
  hubspotScript.type = 'text/javascript';
  hubspotScript.id = 'hs-script-loader';
  hubspotScript.async = true;
  hubspotScript.defer = true;
  hubspotScript.src = '//js.hs-scripts.com/45345015.js';

  // Append the script to <body>
  document.body.appendChild(hubspotScript);
}

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <GlobalAppProvider
        initGlobalState={{
          isFullView: false,
          userImpersonated:
            sessionStorage.getItem('userImpersonated') === 'true',
        }}
      >
        <UserProvider initUser={initUser}>
          <CurrencyProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </CurrencyProvider>
        </UserProvider>
      </GlobalAppProvider>
    </HelmetProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) module.hot.accept(['./locales/i18n'], () => {});

// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
