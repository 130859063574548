import { muiTheme } from 'styles/muiTheme';

const buttonBaseStyles = {
  color: 'primary.back',
  borderRadius: '6px',
  padding: '12px',
};

const dividerStyles = {
  borderRadius: '6px',
  border: '0.20rem solid rgba(0,0,0,0.3)',
  borderColor: muiTheme.palette.primary.main, //colors.primary['main-primary'],
  mb: '12px',
};

export default {
  buttonBaseStyles,
  parentAccordionTitleStyles: {
    paddingX: 0,
    color: 'text.primary',
    '.MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(90deg)',
      color: 'primary.main',
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-90deg)',
      color: 'primary.main',
    },
  },
  sharedAccordionTitleStyles: {
    paddingX: 0,
    color: 'text.primary',
    '.MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(90deg)',
      color: 'primary.main',
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-90deg)',
      color: 'primary.main',
    },
    ':hover': {
      color: 'text.primary',
    },
  },
  accordionStyles: { borderTop: 'none' },
  titleAccordionStyles: { marginLeft: 2, fontWeight: 'bold' },
  expandendAccordionStyles: { padding: 0 },
  expandendTitleAccordionStyles: {
    padding: '12px',
    mb: '4px',
    '&.Mui-expanded': {
      color: 'secondary.main',
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color: 'secondary.main',
    },
  },
  dividerStyles,
  outsideDividerStyles: {
    ...dividerStyles,
  },
  sidebarStyles: {
    backgroundColor: 'primary.contrast',
    position: 'inherit',
    border: 'none',
    padding: '40px 12px',
    transition: 'width 0.3s ease',
  },

  internalSidebarSx: {
    width: 'calc(100% - 82px)',
  },

  listSx: { width: 180 },

  buttonLigthGrayStyles: {
    ...buttonBaseStyles,
    ':hover': {
      backgroundColor: 'secondary.30p',
      color: 'text.primary',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
    ':active': {
      backgroundColor: 'primary.12p',
      color: 'primary.dark',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
  },
  clientButtonStyles: {
    ...buttonBaseStyles,
    ':hover': {
      backgroundColor: 'primary.main',
      color: 'text.secondary',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
    ':active': {
      backgroundColor: 'primary.main',
      color: 'primary.contrast',
    },
  },
  addClientButtonStyles: {
    ...muiTheme.variants.buttonLargePrimary,
    width: 'inset',
    mb: '0.75rem',
    justifyContent: 'center',
    color: 'secondary.dark',
    backgroundColor: 'inherit',
    border: '1px solid',
    borderColor: 'primary.main',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
  },
  addNewButtonStyles: {
    ...muiTheme.variants.buttonLargePrimary,
    width: 'inset',
    mb: '0.75rem',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
    },
  },
  entitiesDetailsStyles: {
    padding: 0,
  },
  goToEntityButtonStyles: {
    ...muiTheme.variants.outlinedButtonLargePrimary,
    width: 'inset',
    mb: '0.5rem',
    justifyContent: 'center',
    height: 'auto',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
    },
  },
  containerStyle: {
    padding: '1em',
  },
  noClienttitle: {
    margin: '1em 0',
    color: 'secondary.light',
  },
  noFiltertitle: {
    margin: '1em 0',
    color: 'text.primary',
    fontWeight: 'bold',
  },
  addNewTaskButtonStyles: {
    ...muiTheme.variants.buttonLargePrimary,
    width: 'inset',
    height: '42px',
    textTransform: 'uppercase',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
    },
  },
  buttonStyle: {
    ...buttonBaseStyles,
    background: 'rgba(156, 150, 142, 0.12)',
    color: 'text.primary',
    mb: '4px',
    ':hover': {
      backgroundColor: 'rgba(156, 150, 142, 0.12)',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
  },
  outlinedButtonStyles: {
    ...buttonBaseStyles,
    border: '1px solid rgba(16, 24, 32, 0.12)',
  },
  dividerSx: {
    mt: '106px',
    mb: '24px',
  },
};
