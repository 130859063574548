import moment from 'moment';
import { AssetToValues, ItemsKeysToValues } from 'constants/asset-liability';
import {
  Folder,
  ItemType,
  VaultFile,
} from 'app/pages/vault/models/vault.types';
import { GENERIC_FOLDER } from 'app/pages/vault/models/files.consts';
import { sortAllGroupItems } from 'utils/assetValues';
import { GroupData } from 'app/features/GroupSlice/types';

export const ASSET_ROW_WIDTH = 260;

export const DEPTH_SPACE = 15;

/**
 * The function `filterFilesByItem` filters files by a specific item ID and converts their content
 * length to megabytes.
 * @param files - The `files` parameter is an array containing file objects. Each file object has
 * properties such as `filePath` and `contentLength`. The `filePath` property is a string representing
 * the file path, and the `contentLength` property is the size of the file content in bytes.
 * @param  itemId - The `itemId` parameter in the `filterFilesByItem` function is a string that
 * represents the unique identifier of an item. The function filters an array of files based on whether
 * the file's path contains the `itemId` at a specific position.
 * @returns The `filterFilesByItem` function takes an array of files and an item ID as input. It
 * filters the files based on whether the file path contains the item ID at a specific position. Then,
 * it maps over the filtered files and adds a new property `size` to each file object, which represents
 * the file size converted from bytes to megabytes using the `bytesToMB` function.
 */
const filterFilesByItem = (files, itemId) => {
  const filteredFiles = files.filter(file => {
    const parts = file.filePath.split('/');
    return parts.length > 1 && parts[2] === itemId;
  });
  return filteredFiles.map(file => ({
    ...file,
    size: file.contentLength,
  }));
};

const filterFilesWithoutItemType = files => {
  const filteredFiles = files.filter(file => {
    const parts = file.filePath.split('/');
    return parts.length === 2;
  });
  return filteredFiles.map(file => ({
    ...file,
    size: file.contentLength,
  }));
};

/**
 * The function creates a folder tree structure without including files from the provided groups data.
 * @returns The function `createFolderTreeWithoutFiles` returns an object with a `children` property.
 * The `children` property is an array of objects, where each object represents a group item. Each
 * group item object contains properties such as `groupId`, `name`, `itemType`, and `children`. The
 * `children` property of each group item object is an array of child items within that group.
 */
export const createFolderTreeWithoutFiles = groups => {
  const data = sortAllGroupItems(groups);
  return {
    children: Object.keys(data).map(key => {
      const children = data[key].map(item => {
        return {
          id: item?._id,
          groupId: groups[0]?._id,
          name: item?.baseContext?.name,
          itemType: AssetToValues[item?.itemType],
        };
      });

      return {
        groupId: groups[0]?._id,
        name: ItemsKeysToValues[key],
        itemType: AssetToValues['GROUP_ASSET'],
        children,
      };
    }),
  };
};

/**
 * The function `createFolderTree` generates a folder tree structure based on input groups and files
 * data.
 * @param groups - Groups is an array containing group objects. Each group object represents a folder
 * in the folder tree structure.
 * @param files - An array of file objects associated with the groups. Each file object represents a
 * file in the folder tree structure.
 * @returns The `createFolderTree` function returns an object with a `children` property. The
 * `children` property is an array of objects representing grouped items. Each object in the array
 * contains information about the group, its children items, total size, last modified date, and a
 * `type` property indicating whether the item is a "folder" or "file".
 */

// Helper to calculate total size and last modified
const calculateFolderMetadata = files => {
  const totalSize = files.reduce(
    (total, file) => total + file.contentLength,
    0,
  );
  const lastModified = files.reduce(
    (latest, file) =>
      moment(file.lastModified).isAfter(latest) ? file.lastModified : latest,
    moment(),
  );
  return { totalSize, lastModified };
};

export const createFolderTree = (
  groups: GroupData[],
  files: VaultFile[],
): { children: Folder[] } => {
  const group = groups[0];
  const data = sortAllGroupItems(groups);

  // Handle the General folder (files without an item type)
  const generalFiles = filterFilesWithoutItemType(files);
  const { totalSize: generalTotalSize, lastModified: generalLastModified } =
    calculateFolderMetadata(generalFiles);

  const genericFolder = {
    groupId: group?._id,
    name: GENERIC_FOLDER.name,
    type: ItemType.folder,
    children: generalFiles.map(file => ({ ...file, type: 'file' })),
    size: generalTotalSize,
    lastModified: generalLastModified,
    owner: group.groupName,
  };

  // Create folders from data
  const children = Object.keys(data).map(key => {
    const childFolders = data[key].map(item => {
      const itemFiles = filterFilesByItem(files, item?._id);
      const { totalSize: itemTotalSize, lastModified: itemLastModified } =
        calculateFolderMetadata(itemFiles);

      return {
        id: item?._id,
        groupId: group?._id,
        name: item?.baseContext?.name,
        itemType: AssetToValues[item?.itemType],
        type: ItemType.folder,
        children: itemFiles.map(file => ({
          ...file,
          itemType: AssetToValues[item?.itemType],
          type: ItemType.file,
        })),
        size: itemTotalSize,
        lastModified: itemLastModified,
        owner: group.groupName,
      };
    });

    const { totalSize: childTotalSize, lastModified: childLastModified } =
      calculateFolderMetadata(childFolders);
    const itemType = data[key][0].itemType;

    return {
      groupId: group?._id,
      name: ItemsKeysToValues[key],
      itemType: AssetToValues[itemType],
      type: ItemType.folder,
      size: childTotalSize,
      children: childFolders,
      lastModified: childLastModified,
      owner: group.groupName,
    };
  });

  // Return the final structure with the "General" folder at the beginning
  return {
    children: [genericFolder, ...children],
  };
};
/**
 * The function `addFileToFolderTree` updates the folder tree structure by adding a file and updating
 * the sizes of the folders, groups, and items accordingly.
 * @param folderTree - The `folderTree` parameter represents the current structure of a folder tree,
 * which includes information about the size of the folders, groups, and items within the tree.
 * @param file - The `addFileToFolderTree` function takes in a `folderTree` object representing a
 * folder structure and a `file` object to be added to the folder tree. The `file` object contains
 * information about the file, including its `filePath` and `size`.
 * @returns The function `addFileToFolderTree` returns an updated folder tree object with the file
 * added to the appropriate group and item within the tree. The total size of the folder tree, group,
 * and item are also updated to reflect the addition of the file.
 */

export const addFileToFolderTree = (folderTree, file) => {
  const parts = file.filePath.split('/');
  const groupId = parts[1]; // Extract groupId
  const itemId = parts[2]; // Extract itemId (if it exists)
  return {
    ...folderTree,
    size: folderTree.size + file.size, // Update the total size of the folder tree
    children: folderTree.children.map(folder => {
      // If groupId doesn't match, return the group as-is
      if (folder.groupId !== groupId) {
        return folder;
      }

      // If itemId is missing, this means the file should go to the "General" folder
      if (!itemId && folder.name === GENERIC_FOLDER.name) {
        return {
          ...folder,
          size: folder.size + file.size, // Update the total size of the folder
          children: [...folder.children, file],
        };
      }

      // If itemId exists, update the corresponding item in the folder
      return {
        ...folder,
        size: folder.size + file.size, // Update the total size of the folder
        children: folder.children.map(item => {
          if (item.id !== itemId) {
            return item;
          }

          return {
            ...item,
            size: item.size + file.size, // Update the total size of the item
            children: [...item.children, file], // Add the file to the specific item
          };
        }),
      };
    }),
  };
};

/**
 * This function deletes a specific file from a folder tree structure based on the file's path.
 * @param folderTree - The `folderTree` parameter represents the folder structure in which the file is
 * located. It is an object that contains information about the folders and files within the structure.
 * Each folder can have children folders and files.
 * @param file - The `deleteFileFromFolderTree` function takes in a `folderTree` object representing a
 * folder structure and a `file` object that needs to be deleted from the folder tree. The `file`
 * object contains a `filePath` property which is used to identify the file to be deleted.
 * @returns The function `deleteFileFromFolderTree` returns an updated `folderTree` object with the
 * specified `file` removed from its children.
 */
export const deleteFileFromFolderTree = (folderTree, file) => {
  const parts = file.filePath.split('/');
  const groupId = parts[1];
  const itemId = parts[2];

  return {
    ...folderTree,
    children: folderTree.children.map(group => {
      if (group.groupId !== groupId) {
        return group;
      }

      return {
        ...group,
        children: group.children.map(item => {
          if (item.id !== itemId) {
            return item;
          }

          return {
            ...item,
            children: item.children.filter(child => child._id !== file._id),
          };
        }),
      };
    }),
  };
};

/**
 * The function `updateFileInFolderTree` updates a file in a folder tree structure based on the file's
 * path.
 * @param folderTree - The `folderTree` parameter represents the folder structure in your application.
 * It contains nested objects representing groups and items within those groups.
 * @param newFile - The `updateFileInFolderTree` function takes in a `folderTree` object representing a
 * tree structure of folders and files, and a `newFile` object representing the file to be updated in
 * the folder tree.
 * @returns The function `updateFileInFolderTree` returns an updated `folderTree` object with the new
 * file added to the appropriate location in the folder tree structure.
 */
export const updateFileInFolderTree = (folderTree, newFile) => {
  const parts = newFile.filePath.split('/');
  const groupId = parts[1];
  const itemId = parts[2];

  return {
    ...folderTree,
    children: folderTree.children.map(group => {
      if (group.groupId !== groupId) {
        return group;
      }

      return {
        ...group,
        children: group.children.map(item => {
          if (item.id !== itemId) {
            return item;
          }

          return {
            ...item,
            children: item.children.map(child => {
              if (child._id !== newFile._id) {
                return child;
              }

              return newFile;
            }),
          };
        }),
      };
    }),
  };
};

/**
 * The `formatName` function in TypeScript removes accents and replaces spaces with hyphens in a given
 * file name.
 * @returns The `formatName` function is returning the `fileName` with accents removed and spaces
 * replaced with hyphens.
 */
export const formatName = fileName => {
  fileName = fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Remove accents
  fileName = fileName.replace(/\s+/g, '-');
  return fileName;
};

/**
 * The function `getFileNameAndExtension` extracts the file name and extension from a given string,
 * removing accents, spaces, special characters, and converting the extension to lowercase.
 * @param {string} name - The function `getFileNameAndExtension` takes a `name` parameter as input,
 * which is expected to be a string representing a file name with an extension. The function then
 * processes this input to extract the file name and extension separately, performing operations such
 * as removing accents, replacing spaces with hyphens
 * @returns The function `getFileNameAndExtension` returns an object with two properties: `fileName`
 * and `extension`. The `fileName` property contains the processed file name without accents, spaces
 * replaced with hyphens, dots, and special characters removed. The `extension` property contains the
 * lowercase extension of the file.
 */
export const getFileNameAndExtension = (name: string) => {
  let fileName = name.substring(0, name.lastIndexOf('.'));
  fileName = fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Remove accents
  fileName = fileName.replace(/\s+/g, '-'); // Replace spaces with hyphens
  fileName = fileName.replace(/[^\w-]/g, ''); // Remove dots and special characters

  const extension = name?.substring(name.lastIndexOf('.') + 1).toLowerCase();

  return { fileName, extension };
};

/**
 * This TypeScript function returns a background color based on the properties of a file object.
 * @returns The function `getBackgroundColor` returns a string value representing the background color
 * based on the properties of the `file` object. If `file.isHighlighted` is true, it returns
 * 'addition.lightGreyLabelColor'. If `file.isProcessing` is true, it returns
 * 'addition.vaultProcessingBg'. Otherwise, it returns 'inherit'.
 */
export const getBackgroundColor = file => {
  switch (true) {
    case file.isHighlighted:
      return 'addition.lightGreyLabelColor';
    case file.isProcessing:
      return 'addition.vaultProcessingBg';
    default:
      return 'inherit';
  }
};
