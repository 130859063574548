import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Totals } from '../Totals/Loadable';
import { Simulation } from './components/Simulation';
import * as style from './style';
import { Button } from 'app/components';
import ConsolidationCalcuationBar from 'app/components/ConsolidationCalcuationBar';

export type Scenario = {
  _id?: string;
  title: string;
  projectHoldings?: any; //TODO:fix This
  buyScenario?: any; // TODO: Fix this type
  sellScenario?: any; // TODO: Fix this type
  benchmarkData?: any; // TODO: Fix this type
};

type Props = {
  isSavedScenario?: boolean;
  scenario: Scenario;
  onSaveScenario?: () => void;
  onClearScenario?: () => void;
  toogleSellModal?: () => void;
  toogleBuyModal?: () => void;
};

/**
 * TODO:
 * Refactor color on text
 */

export const Scenario = ({
  isSavedScenario,
  scenario,
  onSaveScenario,
  onClearScenario,
  toogleSellModal,
  toogleBuyModal,
}: Props) => {
  const { t } = useTranslation();
  const { projectHoldings, buyScenario, sellScenario } = scenario;

  return (
    <Grid id="root-container" sx={style.Container}>
      <Grid id="simulators-container" sx={style.SimulatorsContainer}>
        <Simulation
          isSavedScenario={isSavedScenario}
          title={t('scenarioModeling.simulator.headers.simulateSell')}
          buttonText={t('scenarioModeling.simulator.buttons.sellAsset')}
          emptyText={t('scenarioModeling.simulator.messages.noSellScenario')}
          toggleModal={toogleSellModal}
          data={sellScenario}
        />
        <Simulation
          isSavedScenario={isSavedScenario}
          title={t('scenarioModeling.simulator.headers.simulateBuy')}
          buttonText={t('scenarioModeling.simulator.buttons.buyAsset')}
          emptyText={t('scenarioModeling.simulator.messages.noBuyScenario')}
          toggleModal={toogleBuyModal}
          data={buyScenario}
        />
      </Grid>

      {(scenario?.buyScenario || scenario?.sellScenario) && !isSavedScenario ? (
        <Grid sx={style.ButtonsContainer}>
          <Button sx={style.ClearButton} onClick={onClearScenario}>
            {t('scenarioModeling.simulator.buttons.clearScenario')}
          </Button>
          <Button sx={style.SaveButton} onClick={onSaveScenario}>
            {t('scenarioModeling.simulator.buttons.saveScenario')}
          </Button>
        </Grid>
      ) : null}

      <Typography variant="h6" fontWeight="700" sx={style.ProjectHoldings}>
        {t('scenarioModeling.simulator.headers.projectHoldings')}
      </Typography>
      {scenario?.projectHoldings ? <Totals values={projectHoldings} /> : null}
      {scenario?.benchmarkData ? (
        <ConsolidationCalcuationBar
          heading={t('scenarioModeling.simulator.headers.projectHoldings')}
          nodata={Boolean(
            scenario?.benchmarkData && scenario?.benchmarkData.length > 0,
          )}
          readings={scenario?.benchmarkData}
        />
      ) : null}
    </Grid>
  );
};
