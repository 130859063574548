import { Route, Routes, Navigate } from 'react-router-dom';
import { RequireAuth, RequireVerification } from './utilityRoutes';

import { AddStakeholderPage } from 'app/pages/stakeholders/AddStakeholderPage/Loadable';
import { AddTaskManagerPage } from 'app/pages/task-manager/AddTaskManagerPage/Loadable';
import { ConnectedAccountsPage } from 'app/pages/user-home/ConnectedAccounts/Loadable';
import { ConsolidationPage } from 'app/pages/user-home/consolidation/ConsolidationPage/Loadable';
import { ContinueConnectAccount } from 'app/pages/continue/ContinueConnectAccount/Loadable';
import { EditTaskManagerPage } from 'app/pages/task-manager/EditTaskManagerPage/Loadable';
import { GroupsPage } from 'app/pages/user-home/GroupsPage/Loadable';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { StakeholderPage } from 'app/pages/user-home/StakeholderPage/Loadable';
import { SharedWithMePage } from 'app/pages/user-home/SharedWithMePage/Loadable';
import { TaskManagerPage } from 'app/pages/user-home/TaskManagerPage/Loadable';
import { UserHome } from 'app/pages/user-home/UserHome/Loadable';
// import { UssSignUp } from 'app/pages/uss/UssSignup.deprecated/Loadable';
import { ViewItemPage } from 'app/pages/user-home/consolidation/view-item-page/Loadable';
import { ViewLiabilityPage } from 'app/pages/user-home/consolidation/ViewLiabilityPage/Loadable';
import { ManageUserAccessPage } from 'app/pages/stakeholders/ManageUserAccessPage/Loadable';
import { EditUserConnectionPage } from 'app/pages/stakeholders/EditUserConnectionPage/Loadable';
import { EntitiesViewPage } from 'app/pages/user-home/EntitiesViewPage/Loadable';
import { PublicDocs } from 'app/pages/public/PublicDocs/Loadable';
import { getRoutePath } from 'app/routes/routePath';
import { ContinueMarketingUser } from 'app/pages/continue/ContinueMarketingUser/Loadable';
import { VaultPage } from 'app/pages/vault/page';
import { DashboardPage } from 'app/pages/user-home/DashboardPage/Loadable';
import { ContinueAuth } from 'app/pages/continue/ContinueAuth/Loadable';

import { AdvisorPage } from 'app/pages/user-home/AdvisorPage';
import { useUser } from 'providers/UserProvider';
import { MembershipType } from 'providers/UserProvider/types';

/* import { ForgotPasswordPage } from 'app/pages/uss/ForgotPasswordPage/Loadable'; */
import { ForgotPasswordPage } from 'app/pages/uss-new/forgot-password';

/* import { InvitationPage } from 'app/pages/uss/InvitationPage/Loadable'; */
import { InvitationPage } from 'app/pages/uss-new/invitation';

/* import { UssSignIn } from 'app/pages/uss/UssSignIn/Loadable'; */
import { UssSignIn } from 'app/pages/uss-new/uss-sign-in';

/* import { VerificationPage } from 'app/pages/uss/VerificationPage/Loadable'; */
import { VerificationPage } from 'app/pages/uss-new/verification';

/* import { FaqPage } from 'app/pages/uss/FaqPage'; */
import { FaqPage } from 'app/pages/uss-new/faq';

/* import { ProfileSettingsPage } from 'app/pages/uss/ProfileSettingsPage/Loadable'; */
import { ProfileSettingsPage } from 'app/pages/uss-new/profile-settings';

import { ScenarioModeling } from 'app/pages/scenario-modeling';
// import { withAuthenticationRequired } from '@auth0/auth0-react';

// Wrappers
import { SectionBoundaryWrapper } from 'app/components/SectionBoundaryWrapper';
import { UserHomeBoundaryWrapper } from 'app/pages/user-home/UserHomeBoundaryWrapper';

// const ProtectedRoute = ({ component, ...args }) => {
//   const Component = withAuthenticationRequired(component, args);
//   return <Component />;
// };

export default function AppRoutes() {
  const { user } = useUser();
  return (
    <Routes>
      {/* Home Auth Handler */}
      <Route
        index
        element={
          <RequireAuth locationOverride={getRoutePath({ target: 'userHome' })}>
            <Navigate to={getRoutePath({ target: 'userHome' })} />
          </RequireAuth>
        }
      />

      {/* User self-service */}
      <Route path={getRoutePath({ target: 'uss' })}>
        <Route path="signin" element={<UssSignIn />} />
        <Route path="register" element={<UssSignIn />} />
        <Route path="verification" element={<VerificationPage />} />
        <Route
          path="settings"
          element={
            <RequireAuth>
              <RequireVerification>
                <ProfileSettingsPage />
              </RequireVerification>
            </RequireAuth>
          }
        />

        {/* TODO: Legacy links on backend without uss */}
        <Route path="invitation" element={<InvitationPage />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
      </Route>

      {/* User home */}
      <Route
        path={getRoutePath({ target: 'userHome' })}
        element={
          <RequireAuth>
            <RequireVerification>
              <UserHomeBoundaryWrapper>
                <UserHome />
              </UserHomeBoundaryWrapper>
            </RequireVerification>
          </RequireAuth>
        }
      >
        <Route path="dashboard" element={<SectionBoundaryWrapper />}>
          <Route index element={<DashboardPage />} />
        </Route>

        {user?.account.membershipType !== MembershipType.ADVISOR ? (
          <Route index element={<Navigate to="consolidation" />} />
        ) : (
          <Route index element={<Navigate to="advisors" />} />
        )}

        <Route path="consolidation" element={<SectionBoundaryWrapper />}>
          <Route index element={<ConsolidationPage />} />

          <Route path=":groupId">
            <Route index element={<ConsolidationPage />} />
            <Route path="actions">
              <Route path="view-asset/:itemId" element={<ViewItemPage />} />
              <Route
                path="view-liability/:itemId"
                element={<ViewLiabilityPage />}
              />
            </Route>
          </Route>
        </Route>

        <Route path="stakeholders" element={<SectionBoundaryWrapper />}>
          <Route index element={<StakeholderPage />} />
          <Route path=":groupId">
            <Route index element={<StakeholderPage />} />
            <Route path="actions">
              <Route path="add-stakeholder" element={<AddStakeholderPage />} />
              <Route
                path="manage-access/:stakeholderId"
                element={<ManageUserAccessPage />}
              />
              <Route
                path="edit-user/:stakeholderId"
                element={<EditUserConnectionPage />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="advisors" element={<SectionBoundaryWrapper />}>
          <Route index element={<AdvisorPage />} />
          <Route path=":groupId">
            <Route index element={<AdvisorPage />} />
            <Route path="actions">
              {/* <Route path="add-advisor" element={<AddStakeholderPage />} /> */}
              <Route
                path="manage-access/:stakeholderId"
                element={<ManageUserAccessPage />}
              />
              <Route
                path="edit-user/:stakeholderId"
                element={<EditUserConnectionPage />}
              />
            </Route>
          </Route>
        </Route>

        <Route path="shared" element={<SectionBoundaryWrapper />}>
          <Route index element={<SharedWithMePage />} />
        </Route>

        <Route path="tasks" element={<SectionBoundaryWrapper />}>
          <Route index element={<TaskManagerPage />} />
          <Route path=":groupId">
            <Route index element={<TaskManagerPage />} />
            <Route path="actions">
              <Route path="add-task" element={<AddTaskManagerPage />} />
              <Route
                path="edit-task/:taskId"
                element={<EditTaskManagerPage />}
              />
            </Route>
          </Route>
          <Route path="allMyTasks">
            <Route index element={<TaskManagerPage />} />
          </Route>
        </Route>

        <Route path="vault" element={<SectionBoundaryWrapper />}>
          <Route index element={<VaultPage />} />

          <Route path=":groupId">
            <Route index element={<VaultPage />} />
          </Route>
        </Route>

        <Route path="scenario-modeling" element={<SectionBoundaryWrapper />}>
          <Route index element={<ScenarioModeling />} />

          <Route path=":groupId">
            <Route index element={<ScenarioModeling />} />
          </Route>
        </Route>

        <Route path="groups" element={<GroupsPage />} />
        <Route path="entities" element={<EntitiesViewPage />} />
        <Route path="accounts" element={<ConnectedAccountsPage />} />
      </Route>

      {/* Redirects */}
      <Route path="/continue">
        <Route path="connected-accounts">
          <Route path=":integrationId">
            <Route
              path=":groupId"
              element={
                <RequireAuth>
                  <RequireVerification>
                    <ContinueConnectAccount />
                  </RequireVerification>
                </RequireAuth>
              }
            />
          </Route>
        </Route>
        {/**TODO this route is unnused, we should remove*/}
        <Route path="champions" element={<ContinueMarketingUser />} />
        <Route path="auth" element={<ContinueAuth />} />
      </Route>

      {/* Public Pages */}
      <Route path="/docs">
        <Route path=":docId" element={<PublicDocs />} />
        <Route
          path="faq"
          element={
            <RequireAuth>
              <RequireVerification>
                <FaqPage />
              </RequireVerification>
            </RequireAuth>
          }
        />
      </Route>

      {/* Start: Legacy redirects */}
      <Route
        path="/verification"
        element={<Navigate to="/uss/verification" />}
      />

      <Route path="/invitation" element={<Navigate to="/uss/invitation" />} />

      <Route
        path="/forgot-password"
        element={<Navigate to="/uss/forgot-password" />}
      />
      {/* End: Legacy redirects */}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
