import { muiTheme } from 'styles/muiTheme';

export default {
  fallbackContainer: {
    fontFamily: 'RobotoMedium',
    color: muiTheme.palette.primary.contrastText,
    background: muiTheme.palette.primary.main,
    padding: '1rem',
    textAlign: 'center' as const,
    zIndex: 999,
  },
  heading: {
    marginBottom: '0.5rem',
  },
  errorMessage: {
    color: muiTheme.palette.error.contrastText,
    backgroundColor: muiTheme.palette.error.main,
    padding: '0.5rem',
    borderRadius: '4px',
    marginBottom: '0.5rem',
  },
  button: {
    marginTop: '1rem',
    backgroundColor: muiTheme.palette.secondary.main,
    color: muiTheme.palette.secondary.contrastText,
    padding: '0.5rem 1rem',
    border: 'none',
    cursor: 'pointer',
  },
};
