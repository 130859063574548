import { FallbackProps } from 'react-error-boundary';
import style from './style';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/**
 * Renders a fallback UI whenever an error is caught by `react-error-boundary`.
 * In development mode, it shows detailed error info; in production, it shows a generic message.
 * we are not translating the error message because it is not user-facing
 */
export function ErrorFallback({ error }: FallbackProps) {
  const { t } = useTranslation();
  const isDev = process.env.NODE_ENV === 'development';

  // Generic message from your i18n file
  const fallbackMessage = t('errorBoundary.fallbackMessage');

  return (
    <Box sx={style.fallbackContainer}>
      {/* User-friendly fallback message */}
      <p>{fallbackMessage}</p>

      {/* In development, show the actual error message/stack for debugging */}
      {isDev && error && (
        <Box sx={{ textAlign: 'left', marginTop: '1rem' }}>
          <h4 style={{ marginBottom: '0.5rem' }}>Dev Error Details:</h4>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            <strong>Error:</strong> {error.message}
            {'\n'}
            <strong>Stack:</strong> {error.stack}
          </pre>
        </Box>
      )}
    </Box>
  );
}
