import { groupActions } from 'app/features/GroupSlice';
import { assetLiabilityActions as itemActions } from 'app/features/AssetLiabilitySlice';
import { defaultErrorMessage } from 'constants/error';
import { Dispatch, SetStateAction } from 'react';
import { getInstance } from 'utils/http';
import { OSS_URL } from 'utils/urls';
import { CurrencyStateProps, ValidCurrencies } from './types';

const OssInstance = getInstance(OSS_URL);

export const useChangeBaseCurrency = async (
  base: ValidCurrencies,
  currencyState: CurrencyStateProps,
  setCurrencyState: Dispatch<SetStateAction<CurrencyStateProps>>,
  dispatch: Dispatch<any>,
) => {
  setCurrencyState({
    ...currencyState,
    base: base,
    isFetchingBaseCurrency: true,
  });
  try {
    const { data: response } = await OssInstance.get(
      `/calculation/exchangeRates/${base}`,
    );

    if (!response) {
      throw new Error(`Unable to get rates for ${base}`);
    }

    // get data from api
    const changedState = {
      ...currencyState,
      isFetchingBaseCurrency: false,
      base: base,
      rates: response.conversion_rates,
      timeLastFetched: Date.now(),
    };
    setCurrencyState(changedState);
    localStorage.setItem('currencyState', JSON.stringify(changedState));
    dispatch(groupActions.cleanupGroupOperation());
    dispatch(itemActions.cleanAssetView());
    dispatch(itemActions.cleanLiabilityView());
  } catch (error: any) {
    setCurrencyState({
      ...currencyState,
      changeBaseCurrencyError: {
        name: 'error',
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      },
      isFetchingBaseCurrency: false,
    });
  }
};
