import { createSelector } from '@reduxjs/toolkit';
import { identity } from 'fp-ts/lib/function';

import { RootState } from 'types';
import { ConsolidatedGroupData } from 'constants/keyValue';
import { initialState } from '.';

const filterWithGroup = (isArchived, groupId, task) => {
  let groupCondition = true;
  let archivedCondition = !task.archived;
  if (groupId && groupId !== ConsolidatedGroupData._id) {
    groupCondition = groupId === task.groupId;
  }
  if (isArchived) {
    archivedCondition = task.archived;
  }
  return groupCondition && archivedCondition;
};

const sliceUserTasks = (state: RootState) => state?.userTasks || initialState;

export const selectUserTasks = createSelector([sliceUserTasks], identity);

export const selectCurrentUserTask = createSelector(
  [sliceUserTasks, (_, userTaskId?: string) => userTaskId],
  (state, userTaskId) => state.userTasks.find(({ _id }) => userTaskId === _id),
);

export const selectArchivedUserTasks = groupId =>
  createSelector([sliceUserTasks], state =>
    state.userTasks.filter(task => filterWithGroup(true, groupId, task)),
  );
export const selectVisibleUserTasks = groupId =>
  createSelector([sliceUserTasks], state =>
    state.userTasks.filter(task => filterWithGroup(false, groupId, task)),
  );

export const selectRelevantUserTasks = groupId =>
  createSelector([sliceUserTasks], state =>
    state.userTasks
      .filter(
        task =>
          filterWithGroup(false, groupId, task) && task.status !== 'completed',
      )
      .sort((taskA, taskB) => {
        const dueDateA = new Date(taskA.dueDate);
        const dueDateB = new Date(taskB.dueDate);
        dueDateA.setHours(0, 0, 0, 0);
        dueDateB.setHours(0, 0, 0, 0);
        if (dueDateA.valueOf() === dueDateB.valueOf()) {
          return taskA.priority < taskB.priority ? 1 : -1;
        }
        return dueDateA.valueOf() > dueDateB.valueOf() ? 1 : -1;
      })
      .slice(0, 5),
  );

export const selectUserTasksIsFetching = createSelector(
  [sliceUserTasks],
  state => state.isFetching,
);

export const selectUserTasksIsLoaded = state => state.userTasks.loaded;

export const selectUserTasksIsModifying = createSelector(
  [sliceUserTasks],
  state => state.isModifying,
);

export const selectUserTasksLoaded = createSelector(
  [sliceUserTasks],
  state => state.loaded,
);

export const selectUserTasksIsSuccess = createSelector(
  [sliceUserTasks],
  state => state.isSuccess,
);

export const selectStatusTask = createSelector(
  [sliceUserTasks],
  state => state.statusTasks,
);

export const selectArchivedTask = createSelector(
  [sliceUserTasks],
  state => state.typeTasks,
);

export const selectAddNewTask = createSelector(
  [sliceUserTasks],
  state => state.addNewTask,
);
