export const searchInput = {
  width: '451px',
  position: 'relative',
};

export const searchResults = {
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  maxHeight: '150px',
  overflowY: 'auto',
  backgroundColor: 'white',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'addition.lightGreyLabelColor',
  borderRadius: '4px',
  zIndex: 1,
};
