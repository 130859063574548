import { PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './ErrorFallback';

interface ErrorBoundaryProps extends PropsWithChildren<{}> {}

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        // WIP: log to an error reporting service (Sentry)
        console.error('Error caught by ErrorBoundary:', error, info);
      }}
      onReset={() => {
        // Optional: reset some global state if needed (?)
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
