import { ErrorBoundary } from 'app/components/ErrorBoundary';
import { Outlet } from 'react-router-dom';

/*
  - Wraps a major route section (e.g., "/vault") in an ErrorBoundary.
  - Uses <Outlet/> so any nested routes under this path are caught
    if they throw errors in the render phase.
*/
export function SectionBoundaryWrapper() {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}
