import { useEffect, useState, useMemo } from 'react';
import { useGetFilesByGroupsQuery } from '../services';
import { useDispatch, useSelector } from 'react-redux';
import { selectContactListByGroups } from 'app/features/AddressBookSlice/selectors';
import { createFolderTree } from 'app/pages/vault/utils/files.utils';
import { GroupData } from 'app/features/GroupSlice/types';
import {
  highlightMatchedItem,
  performFileOperation,
} from '../utils/entities.utils';
import { useToastSlice } from 'app/features/ToastSlice';
import { Entity, SearchResult, FileOperation } from '../models/vault.types';

const useEntitiesData = (
  groups: GroupData[],
  searchedItem?: SearchResult,
  fileOperation?: FileOperation,
): Entity[] => {
  const dispatch = useDispatch();
  const { actions: toastActions } = useToastSlice();
  const [entities, setEntities] = useState<Entity[]>([]);
  const groupIds = useMemo(() => groups.map(group => group._id), [groups]);
  const { data: filesData, error } = useGetFilesByGroupsQuery(groupIds);

  const stakeholdersByGroupId = useSelector(state =>
    selectContactListByGroups(state, groupIds),
  );

  useEffect(() => {
    if (!filesData || error || groups.length === 0) return;

    let newEntities: Entity[] = [];

    newEntities = groups.map(group => ({
      groupId: group._id,
      group,
      files: createFolderTree([group], filesData[group._id]?.payload || []),
      stakeholders: stakeholdersByGroupId[group._id] || [],
    }));

    if (searchedItem) {
      newEntities = highlightMatchedItem(newEntities, searchedItem.item);
    }

    if (fileOperation) {
      newEntities = performFileOperation(
        newEntities,
        fileOperation,
        dispatch,
        toastActions,
      );
    }

    setEntities(newEntities);
  }, [
    filesData,
    error,
    groups,
    searchedItem,
    fileOperation,
    stakeholdersByGroupId,
    dispatch,
    toastActions,
  ]);
  return entities;
};

export default useEntitiesData;
