import { Option } from 'app/components/Inputs';

export enum DistributionTypeList {
  DIVIDEND = 'DIVIDEND',
  CAPITAL = 'CAPITAL',
  INTEREST = 'INTEREST',
  FROM_SALE = 'FROM_SALE',
  SPECIAL = 'SPECIAL',
}

export enum DistributionSpecialTypeList {
  RETURN_CAPITAL = 'RETURN_CAPITAL',
}

export enum GeneralTransactionsTypeList {
  INITIAL_TRANSACTION = 'INITIAL_TRANSACTION',
}

export enum GroupOneOperationTypeList {
  UPDATE = 'UPDATE',
  UPDATE_COMMITMENT = 'UPDATE_COMMITMENT',
  COST_ADJUSTMENT = 'COST_ADJUSTMENT',
}

export enum GroupTwoOperationTypeList {
  UPDATE = 'UPDATE',
  UPDATE_COMMITMENT = 'UPDATE_COMMITMENT',
  FEE_DISTRIBUTION = 'FEE_DISTRIBUTION',
  INCOME_DISTRIBUTION = 'INCOME_DISTRIBUTION',
}

export enum SafeTypeList {
  UPDATE_VALUATION = 'UPDATE_VALUATION',
}

export enum GroupThreeOperationTypeList {
  UPDATE = 'UPDATE',
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum GroupFiveOperationTypeList {
  UPDATE = 'UPDATE',
  UPDATE_VALUATION = 'UPDATE_VALUATION',
}

export enum GroupSixOperationTypeList {
  ADJUSTED_INTEREST = 'ADJUSTED_INTEREST',
  ACCURED_INTEREST = 'ACCURED_INTEREST',
  INTEREST_REPAYMENT = 'INTEREST_REPAYMENT',
  PRINCIPAL_ADJUSTMENT = 'PRINCIPAL_ADJUSTMENT',
  PRINCIPAL_REPAYMENT = 'PRINCIPAL_REPAYMENT',
}

export enum InsuranceOperationTypeList {
  COST = 'COST',
  CASH_SURRENDER = 'CASH_SURRENDER',
  DEATH_BENEFIT = 'DEATH_BENEFIT',
}

export enum OptionsRSUOperationTypeList {
  VESTED = 'VESTED',
  GRANTED = 'GRANTED',
  EXERCISED = 'EXERCISED',
}

export enum OthersLiabilityOperationTypeList {
  INCREASE_LIABILITY = 'INCREASE_LIABILITY',
  LIABILITY_PAYMENT = 'LIABILITY_PAYMENT',
}

export const TransactionOptionsRSUTypeList = Object.assign(
  {},
  GeneralTransactionsTypeList,
  OptionsRSUOperationTypeList,
);

export const TransactionInsuranceTypeList = Object.assign(
  {},
  GeneralTransactionsTypeList,
  DistributionTypeList,
  InsuranceOperationTypeList,
);

export const TransactionGroupOneTypeList = Object.assign(
  GeneralTransactionsTypeList,
  DistributionTypeList,
  GroupOneOperationTypeList,
  DistributionSpecialTypeList,
);

export const TransactionGroupTwoTypeList = Object.assign(
  {},
  GeneralTransactionsTypeList,
  DistributionSpecialTypeList,
  GroupTwoOperationTypeList,
);

export const TransactionGroupThreeTypeList = Object.assign(
  DistributionSpecialTypeList,
  GeneralTransactionsTypeList,
  DistributionTypeList,
  GroupThreeOperationTypeList,
);

export const TransactionGroupFiveTypeList = Object.assign(
  {},
  GeneralTransactionsTypeList,
  DistributionTypeList,
  GroupFiveOperationTypeList,
);

export const TransactionGroupSixTypeList = Object.assign(
  {},
  GeneralTransactionsTypeList,
  GroupSixOperationTypeList,
);

export const TransactionSafeTypeList = Object.assign(
  {},
  GeneralTransactionsTypeList,
  SafeTypeList,
);

export const TransactionOtherLiabilityTypesList = Object.assign(
  {},
  GeneralTransactionsTypeList,
  OthersLiabilityOperationTypeList,
);

export type DistributionType = keyof typeof DistributionTypeList;
export type DistributionSpecialType = keyof typeof DistributionSpecialTypeList;
export type GeneralTransactionsType = keyof typeof GeneralTransactionsTypeList;
export type GroupOneOperationType = keyof typeof GroupOneOperationTypeList;
export type GroupTwoOperationType = keyof typeof GroupTwoOperationTypeList;
export type GroupThreeOperationType = keyof typeof GroupThreeOperationTypeList;
export type GroupFiveOperationType = keyof typeof GroupFiveOperationTypeList;
export type GroupSixOperationType = keyof typeof GroupSixOperationTypeList;
export type OptionsRSUOperationType = keyof typeof OptionsRSUOperationTypeList;
export type InsuranceOperationType = keyof typeof InsuranceOperationTypeList;
export type SafeOperationType = keyof typeof SafeTypeList;
export type OtherLiabilityOperationType =
  keyof typeof OthersLiabilityOperationTypeList;

export type TransactionInsuranceTypes =
  | GeneralTransactionsType
  | InsuranceOperationType;

export type TransactionOptionsRSUTypes =
  | GeneralTransactionsType
  | OptionsRSUOperationType;

export type TransactionGroupOneTypes =
  | GeneralTransactionsType
  | DistributionType
  | GroupOneOperationType
  | DistributionSpecialType;

export type TransactionGroupTwoTypes =
  | GeneralTransactionsType
  | DistributionSpecialType
  | GroupTwoOperationType;

export type TransactionGroupThreeTypes =
  | GeneralTransactionsType
  | DistributionType
  | DistributionSpecialType
  | GroupThreeOperationType;

export type TransactionGroupFiveTypes =
  | GeneralTransactionsType
  | DistributionType
  | GroupFiveOperationType;

export type TransactionGroupSixTypes =
  | GeneralTransactionsType
  | GroupSixOperationType;

export type TransactionOtherLiabilityTypes =
  | GeneralTransactionsType
  | OtherLiabilityOperationType;

export type TransactionGroupSafeTypes = GeneralTransactionsType | SafeTypeList;

export const buildOptionList = (list, translations): Option[] => {
  return Object.keys(list)
    .map(
      (type): Option => ({
        value: type,
        label: translations[type],
      }),
    )
    .filter(({ label }) => label);
};
