import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { ConsolidatedGroupData } from 'constants/keyValue';
import { initialState } from '.';
import { ContactSummary } from './types';

const sliceAddressBook = (state: RootState) =>
  state?.addressBook || initialState;

export const selectUserConnectionIsLoaded = createSelector(
  [sliceAddressBook],
  state => state.loaded && state.isSuccess,
);

export const selectContactListIsLoaded = createSelector(
  [sliceAddressBook],
  state => state.contactListLoaded && state.isSuccess,
);

export const selectContactFilteredListIsLoaded = createSelector(
  [sliceAddressBook],
  state => state.contactFilteredListLoaded && state.isSuccess,
);

export const selectUserAccessIsLoaded = createSelector(
  [sliceAddressBook],
  state => state.accessDataLoaded && state.isSuccess,
);

export const selectConnectionSummaryIsLoaded = createSelector(
  [sliceAddressBook],
  state => state.connectionItemSummaryLoaded && state.isSuccess,
);

export const selectUpdateStatusIsLoaded = createSelector(
  [sliceAddressBook],
  state => state.isUpdating && state.isSuccess,
);

export const selectUserConnectionIsAdded = createSelector(
  [sliceAddressBook],
  state => state.isAdding && state.isSuccess,
);

export const createImpersonateUser = createSelector(
  [sliceAddressBook],
  state => state.isAdding && state.isSuccess,
);

export const selectImpersonateToken = createSelector(
  [sliceAddressBook],
  state => state.impersonateToken,
);
//TODO refator all the impersonating selectors
export const selectIsImpersonating = createSelector(
  [sliceAddressBook],
  state => sessionStorage.getItem('userImpersonated') === 'true',
);

export const selectloadImpersonatedClient = createSelector(
  [sliceAddressBook],
  state => state.loadImpersonatedClient,
);

export const selectgetImpersonatedClient = createSelector(
  [sliceAddressBook],
  state => state.impersonatedClient,
);

export const selectImpersonateTokenSuccess = createSelector(
  [sliceAddressBook],
  state => state.isSuccess && state.loaded,
);

export const selectUserConnectionIsUpdated = createSelector(
  [sliceAddressBook],
  state => state.isModifying && state.isSuccess,
);

export const selectUserConnectionIsDeleted = createSelector(
  [sliceAddressBook],
  state => state.isDeleting && state.isSuccess,
);

export const selectUserAccessData = createSelector(
  [sliceAddressBook],
  state => state.contactAccessData,
);

export const selectConnectionItemSummary = createSelector(
  [sliceAddressBook],
  state => state.connectionItemSummary,
);

export const selectUserConnectionListByGroup = createSelector(
  [
    sliceAddressBook,
    (_, groupId?: string) => groupId,
    (_, __?: string, onlyExistent?: boolean) => onlyExistent,
  ],
  (state, groupId, onlyExistent) => {
    const contactSummaryList =
      !groupId || groupId === ConsolidatedGroupData._id
        ? state.contactSummaryList
        : state.contactSummaryList?.filter(({ groupIdList }) =>
            groupIdList.includes(groupId),
          );
    if (onlyExistent) {
      return contactSummaryList.filter(
        ({ targetUserId, targetUserMeta }) =>
          targetUserId !== targetUserMeta.email,
      );
    }
    return contactSummaryList;
  },
);

export const selectContactListByGroup = createSelector(
  [sliceAddressBook, (_, groupId?: string) => groupId],
  (state, groupId) => {
    const contactSummaryList =
      !groupId || groupId === ConsolidatedGroupData._id
        ? state.contactSummaryList
        : state.contactSummaryList?.filter(({ groupIdList }) =>
            groupIdList.includes(groupId),
          );

    return contactSummaryList;
  },
);

export const selectContactListByGroups = createSelector(
  [sliceAddressBook, (_, groupIds?: string[]) => groupIds],
  (state, groupIds) => {
    if (!groupIds || groupIds.length === 0) {
      return {};
    }

    const groupContactsMap = groupIds.reduce(
      (acc, groupId) => {
        acc[groupId] =
          state.contactSummaryList?.filter(({ groupIdList }) =>
            groupIdList.includes(groupId),
          ) || [];
        return acc;
      },
      {} as Record<string, ContactSummary[]>,
    );
    return groupContactsMap;
  },
);

export const selectContactFilteredList = createSelector(
  [sliceAddressBook],
  state => state.contactSummaryFilteredList,
);

export const selectContactImpersonatedList = createSelector(
  [sliceAddressBook],
  state => {
    const contactSummaryList = state.contactImpersonatedSummaryList;
    return contactSummaryList;
  },
);

export const selectContactByUserId = createSelector(
  [sliceAddressBook, (_, userId: string) => userId],
  (state, userId) => {
    const contactSummary = state.contactSummaryList?.find(
      ({ targetUserId }) => targetUserId === userId,
    );
    return contactSummary;
  },
);

export const selectUserConnectionByTargetId = createSelector(
  [sliceAddressBook, (_, targetId: string) => targetId],
  (state, targetId) =>
    state.contactSummaryList.find(
      ({ targetUserId }) => targetId === targetUserId,
    ),
);
