import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'app/components/ErrorBoundary';

/*
  Similar to SectionBoundaryWrapper, but we accept children 
  directly instead of using <Outlet/> because UserHome 
  is rendered here as a single component.
*/
export function UserHomeBoundaryWrapper({ children }: PropsWithChildren<{}>) {
  return <ErrorBoundary>{children}</ErrorBoundary>;
}
